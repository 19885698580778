import * as React from 'react'
import styled from '@emotion/styled'
import { Box, Column, Section } from 'rbx'
import { css } from '@emotion/core'
import Page from '../../components/Page'
import IndexLayout from '../../layouts'
import StyledContainer from '../../components/Container'
import { StyledButton } from '../../components/Button'
import { SubscriptionCard } from '../../components/Card'
import { dimensions } from '../../styles/variables'

const PageHeader = styled(Section)`
  text-align: center;

  p {
    font-size: ${dimensions.fontSize.large}px;
  }
`

const IndexPage = () => (
  <IndexLayout>
    <Page>
      <StyledContainer>
        <PageHeader>
          <h2>Flexible pricing built for businesses of all sizes.</h2>
        </PageHeader>

        <Column.Group multiline>
          <Column tablet={{ size: 'half' }} widescreen={{ size: 'one-third' }}>
            <SubscriptionCard>
              <Box>
                <h5 className="header">14-day Trial</h5>
                <h2 className="price">Free</h2>

                <p>For those who want to try it out</p>
                <hr />

                <p>45-minute order import interval</p>
                <p>10 order export limit</p>
                <p>Analytics</p>
                <p>Email notifications</p>

                <StyledButton color="primary" fullwidth outlined>
                  Subscribe
                </StyledButton>
              </Box>
            </SubscriptionCard>
          </Column>

          <Column tablet={{ size: 'half' }} widescreen={{ size: 'one-third' }}>
            <SubscriptionCard recommended>
              <Box className="recommended">
                <h5 className="header">Starter</h5>
                <h2 className="price">$25.00/mo</h2>

                <p>Perfect for small-scale retailers</p>
                <hr />

                <p>30-minute order import interval</p>
                <p>30 order export limit</p>
                <p>Analytics</p>
                <p>Email notifications</p>

                <StyledButton color="primary" fullwidth outlined>
                  Subscribe
                </StyledButton>
              </Box>
            </SubscriptionCard>
          </Column>

          <Column tablet={{ size: 'half' }} widescreen={{ size: 'one-third' }}>
            <SubscriptionCard>
              <Box>
                <h5 className="header">Plus</h5>
                <h2 className="price">$60.00/mo</h2>

                <p>Robust and tailored for large enterprises</p>

                <hr />

                <p>15-minute order import interval</p>
                <p>Unlimited order export limit</p>
                <p>Analytics</p>
                <p>Email notifications</p>

                <StyledButton color="primary" fullwidth outlined>
                  Subscribe
                </StyledButton>
              </Box>
            </SubscriptionCard>
          </Column>
        </Column.Group>

        <Section
          css={css`
            text-align: center;
          `}
        >
          <h6>All plans include:</h6>
          <ul>
            <li>Email support</li>
            <li>Unlimited orders</li>
            <li>Unlimited add-ons</li>
            <li>24/7 phone support</li>
          </ul>
        </Section>
      </StyledContainer>
    </Page>
  </IndexLayout>
)

export default IndexPage
